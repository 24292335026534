// react component
import {React} from 'react';
import {Link} from 'react-router-dom';

// material-ui component
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import axios from "axios";

const ColorButton = withStyles((theme) => ({
  root: {
    // color: theme.palette.getContrastText(theme.palette.success.main),
    color: '#fff',
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      backgroundColor: theme.palette.success.light,
    },
  },
}))(Button);

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

export default function NavigationBar(props) {
  const classes = useStyles();

  const handleClick = action => {
    const message = {
      0: 'スタッフを呼びます。よろしいですか？',
      1: 'お会計をします。よろしいですか？',
    }
    if (!window.confirm(message[action])) {
      return;
    }

    switch (action) {
      case 0:
        break;
      case 1:
        break;
    }
    const data = {
      slipId: props.slip.id,
      action,
    };
    axios.post(process.env.REACT_APP_API_ENDPOINT + '/api/order/notification', data)
      .then((results) => {
          // すでにお会計済みの場合
          if (results.data.status === 1) {
            alert('すでにお会計を済みとなっています。またのお越しをお待ちしております。');
            window.location.href = process.env.REACT_APP_BASE_URL + '/thankyou';
          } else if (results.data.status === 2) {
            alert('すでにお会計中となっています。計算書をお持ちしますのでお席にてお待ちください。');
            window.location.href = process.env.REACT_APP_BASE_URL + '/check';
          }

          if (action === 1) {
            const dataCheck = {
              slipId: props.slip.id,
            };
            axios.post(process.env.REACT_APP_API_ENDPOINT + '/api/order/check', dataCheck)
              .then((results) => {
                  window.location.href = process.env.REACT_APP_BASE_URL + '/check';
                }
              );
          }
        },
      );
  }

  return (
    <>
      {props.slip.status === 0 &&
      <div className={classes.root}>
        <ColorButton
          variant="contained"
          color="primary"
          size="small"
          onClick={() => handleClick(0)}
        >
          スタッフ呼出
        </ColorButton>
        {/*<Button*/}
        {/*  variant="contained"*/}
        {/*  color="secondary"*/}
        {/*  size="small"*/}
        {/*  onClick={() => handleClick(1)}*/}
        {/*>*/}
        {/*  お会計*/}
        {/*</Button>*/}
      </div>
      }
    </>
  );
}
