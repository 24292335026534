import React from "react";
import Backdrop from "@material-ui/core/Backdrop";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 100000,
    color: '#fff',
  },
  progressPaper: {
    padding: 10,
  },
  circularProgress: {
    marginTop: 10,
  },
  message: {
    margin: 10,
  },
}));

export default function Progress(props) {
  const classes = useStyles();
  return (
    <>
      <Backdrop
        className={classes.backdrop}
        open={props.open}
      >
        <Paper
          className={classes.progressPaper}
          elevation={3}
        >
          <CircularProgress
            className={classes.circularProgress}
            color="inherit"
          />
          <Typography
            className={classes.message}
            variant="h6"
            gutterBottom
          >
            {props.message}
          </Typography>
        </Paper>
      </Backdrop>
    </>
  );
}

Progress.defaultProps = {
  open: false,
  message: false,
};

Progress.propTypes = {
  open: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
}
