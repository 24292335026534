import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';

// library
import axios from 'axios';

export default function OrderDialog(props) {
  const {slip, selectedMenu, handleClose, open} = props;
  const [quantity, setQuantity] = React.useState(1);
  const [selectedSubMenu, setSelectedSubMenu] = React.useState(0);

  if (selectedMenu.subMenu === undefined) {
    selectedMenu.subMenu = [];
  }

  const handleChange = (event) => {
    setSelectedSubMenu(parseInt(event.target.value));
  };

  const handleCancel = () => {
    handleClose();
    setQuantity(1);
    setSelectedSubMenu(0);
  };

  const menuName = () => {
    let menuName = selectedMenu.menu.menu_name;

    if (!!selectedMenu.menu.campaign_menu_message) {
      menuName = selectedMenu.menu.campaign_menu_message.prefix
        + selectedMenu.menu.menu_name
        + selectedMenu.menu.campaign_menu_message.suffix;
    }

    return menuName;
  }

  const handleCart = () => {
    let data = {
      slipId: slip.id,
      menuName: menuName(),
      price: selectedMenu.menu.price,
      menuId: selectedMenu.menu.id,
      subMenuId: null,
      subMenuName: '',
      subMenuPrice: 0,
      quantity,
    };

    let subMenu = null;
    if (selectedMenu.subMenu.length > 0) {
      subMenu = selectedMenu.subMenu[selectedSubMenu];
      data = {
        ...data,
        subMenuId: subMenu.id,
        subMenuName: subMenu.sub_menu_name,
        subMenuPrice: subMenu.price,
      }
    }

    props.handleCart(data);
    setQuantity(1);
    setSelectedSubMenu(0);
  };

  const handleChangeQuantity = (event) => {
    setQuantity(parseInt(event.target.value, 10));
  };

  const handleQuantityPlus = () => {
    setQuantity(quantity + 1);
  };
  const handleQuantityMinus = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  return (
    <div>
      {/*<Button variant="outlined" color="primary" onClick={handleClickOpen}>*/}
      {/*  Open form dialog*/}
      {/*</Button>*/}
      <Dialog open={open} onClose={handleCancel}
              aria-labelledby="form-dialog-title">
        <DialogTitle
          id="form-dialog-title">{menuName()}</DialogTitle>
        <DialogContent dividers>
          <DialogContentText
            align="center"
            variant="h6"
          >
            {selectedMenu.menu.price}円
          </DialogContentText>
          <Divider variant="middle"/>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            // spacing={3}
          >
            <Grid
              item
              xs={12}
            >
              <Typography
                variant="h6"
                align="center"
              >
                注文数
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <IconButton
                color="secondary"
                onClick={handleQuantityMinus}
              >
                <Icon style={{fontSize: 30}}>remove_circle</Icon>
              </IconButton>
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="h6"
                align="center"
              >
                {quantity}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <IconButton
                color="primary"
                onClick={handleQuantityPlus}
              >
                <Icon style={{fontSize: 30}}>add_circle</Icon>
              </IconButton>
            </Grid>
          </Grid>
          <Divider variant="middle"/>
          <FormControl
            component="fieldset"
            align="center"
          >
            {/*<FormLabel component="legend">Gender</FormLabel>*/}
            <RadioGroup aria-label="subMenu" name="subMenu1"
                        value={selectedSubMenu} onChange={handleChange}>
              {selectedMenu.subMenu.map((item, index) => {
                return (
                  <FormControlLabel value={index} control={<Radio/>}
                                    label={`${item.sub_menu_name}  + ${item.price}円`}/>
                );
              })}
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="secondary">
            キャンセル
          </Button>
          <Button onClick={handleCart} color="primary">
            カートに追加
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
