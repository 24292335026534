import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Grid from '@material-ui/core/Grid';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Skeleton from '@material-ui/lab/Skeleton';

import PropTypes from "prop-types";
import Status from '../element/Status'
import Spacer from "./Spacer";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "unset",
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  statLabel: {
    fontSize: 12,
    color: theme.palette.grey[500],
    fontWeight: 500,
    fontFamily:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    margin: 0,
  },
  statValue: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 4,
    letterSpacing: '1px',
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ElevationScroll(props) {
  const {children, window} = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default function HistoryDialog(props) {
  const {loading = false} = props;
  const classes = useStyles();

  const getOrderTime = (time) => {
    return time.slice(5, time.length);
  }

  const getMenuName = (detail) => {
    return detail.take_out === 1 ? `[お持ち帰り] ${detail.menu_name}` : `${detail.menu_name}`;
  }

  return (
    <div>
      <Dialog fullScreen open={props.open} onClose={props.onCloseDialog}
              TransitionComponent={Transition}>
        {/*<CssBaseline/>*/}
        {/*<ElevationScroll {...props}>*/}
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit"
                        onClick={props.onCloseDialog} aria-label="close">
              <CloseIcon/>
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              注文履歴
            </Typography>
          </Toolbar>
        </AppBar>
        {/*</ElevationScroll>*/}
        <DialogContent
          className={classes.root}
          // dividers
        >
          <div className={classes.tableWrapper}>
            <List className={classes.root}>
              {/*{(loading ? Array.from(new Array(3)) : props.history.slip_details).map((row, index) => {*/}
              {loading ? (
                Array.from(new Array(15)).map((row, index) => {
                  return (
                    <>
                      <ListItem
                        dense
                      >
                        <Grid
                          container
                          direction={"row"}
                          spacing={1}
                          alignItems="center"
                        >
                          <Grid item xs>
                            <Skeleton
                              variant="circle"
                              width={30}
                              height={30}
                              animation="wave"
                              // style={{marginBottom: 6}}
                            />
                          </Grid>
                          <Grid item xs>
                            <Typography
                              variant="subtitle2"
                              align="center"
                            >
                              <Skeleton
                                animation="wave"
                              />
                            </Typography>
                          </Grid>
                          <Grid item xs>
                            <Typography
                              variant="subtitle2"
                              align="center"
                            >
                              <Skeleton
                                animation="wave"
                              />
                            </Typography>
                          </Grid>
                          <Grid item xs>
                            <Typography
                              variant="subtitle2"
                              align="center"
                            >
                              <Skeleton
                                animation="wave"
                              />
                            </Typography>
                          </Grid>
                          <Spacer/>
                          <Grid item xs>
                            <Typography
                              variant="subtitle2"
                              align="center"
                            >
                              <Skeleton
                                animation="wave"
                              />
                            </Typography>
                          </Grid>
                        </Grid>
                      </ListItem>
                      <Divider/>
                    </>
                  );
                })
              ) : (
                props.history.slip_details.map((row) => {
                  const labelId = `checkbox-list-label-${row.id}`;

                  return (
                    <>
                      <ListItem
                        key={row.id}
                        role={undefined}
                        dense
                        button
                      >
                        <Grid
                          container
                          spacing={1}
                          alignItems="center"
                        >
                          <Grid item>
                            <Status status={row.status}/>
                          </Grid>
                          <Grid item>
                            <Typography
                              variant="caption"
                              align="center"
                            >
                              {`${getOrderTime(row.created_at)}`}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                          >
                            <Typography
                              variant="subtitle2"
                              align="center"
                            >
                              {getMenuName(row)}
                            </Typography>
                            <Typography
                              variant="caption"
                              align="center"
                            >
                              {`${row.sub_menu_name}`}
                            </Typography>
                          </Grid>
                          <Spacer/>
                          <Grid item>
                            {`x ${row.quantity}`}
                          </Grid>
                        </Grid>
                      </ListItem>
                      <Divider
                        // variant="inset"
                        // component="li"
                      />
                    </>
                  );
                })
              )}
            </List>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

HistoryDialog.defaultProps = {
  history: {
    slip_details: [],
  },
};

HistoryDialog.propTypes = {
  history: PropTypes.objectOf(
    PropTypes.shape({
      slip_details: PropTypes.array,
    })
  ).isRequired,
}
