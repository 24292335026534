import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import RestoreIcon from '@material-ui/icons/Restore';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Icon from '@material-ui/core/Icon';
import Badge from '@material-ui/core/Badge';
import {withStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  // root: {
  //   width: 500,
  // },
  wrapper: {
    display: 'block',
    width: '100%',
    position: 'fixed',
    left: 0,
    bottom: 0,
    zIndex: 1000,
    textAlign: 'center',
  },
  // root: {
  // [theme.breakpoints.up('md')]: {
  //   display: 'none',
  // },
  // },
  button: {
    maxWidth: '100%', // ボタンが横一杯に広がって欲しくない時はコメントアウト
  },
}));

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -5,
    top: 5,
    border: `2px solid ${theme.palette.primary}`,
    padding: '0 4px',
  },
}))(Badge);

export default function BottomMenu(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  return (
    <>
      <BottomNavigation
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        showLabels
        className={classes.wrapper}
      >
        {/*<BottomNavigationAction label="メニュー" icon={<Icon>menu_book</Icon>}/>*/}
        <BottomNavigationAction
          label="注文履歴"
          icon={<RestoreIcon/>}
          onClick={props.handleHistoryOpen}
        />
        <BottomNavigationAction
          label="カート"
          icon={
            <StyledBadge
              color="secondary"
              badgeContent={props.cartCount}
              // badgeContent="10"
            >
              <Icon>shopping_cart</Icon>
            </StyledBadge>
          }
          onClick={props.handleCartOpen}
        />
        <BottomNavigationAction
          label="注文送信"
          // icon={<Icon>send</Icon>}
          icon={
            <StyledBadge
              color="secondary"
              badgeContent={props.cartCount}
              // badgeContent="10"
            >
              <Icon>send</Icon>
            </StyledBadge>
          }
          onClick={props.handleOrder}
        />
      </BottomNavigation>

    </>
  );
}
