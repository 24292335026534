// react component
import React from 'react';
import {useParams} from 'react-router-dom';
import PropTypes from 'prop-types';

// material-ui component
import Grid from '@material-ui/core/Grid';
import SwipeableViews from 'react-swipeable-views';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

// library
import axios from 'axios';
import _ from 'lodash';

// original component
import MenuCard from '../component/MenuCard';

// menu data
import MenuJson from '../data/menu.json';
import OrderDialog from "../component/OrderDialog";

// element
import ScrollToTop from "../element/ScrollToTop";
import PricingCardDemo from "../component/PricingCard";
import BottomMenu from "../element/BottomMenu";
import CartDialog from "../element/CartDialog";
import HistoryDialog from "../element/HistoryDialog";
import Skeleton from "@material-ui/lab/Skeleton";

function TabPanel(props) {
  const {
    children,
    value,
    index,
    content,
    menus,
    handleClickOpen,
    loading,
    ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      <Box p={3}>
        <Typography>
          {loading ? (
            <Skeleton
              animation="wave"
              style={{margin: 6}}
            />
          ) : (
            `${content}`
          )}
        </Typography>
      </Box>
      {loading ? (
        <Grid container justify="center">
          <Grid container item xs={12} spacing={3}>
            {Array.from(new Array(6)).map((row, index) => {
              return (
                <>
                  <Grid item xs={6} sm={3}>
                    <Skeleton
                      variant="rect"
                      height={70}
                      animation="wave"
                      style={{margin: 6}}
                    />
                    <Skeleton
                      variant="rect"
                      height={10}
                      animation="wave"
                      style={{margin: 6}}
                    />
                    <Skeleton
                      variant="rect"
                      height={10}
                      animation="wave"
                      style={{margin: 6}}
                    />
                  </Grid>
                </>
              );
            })}
          </Grid>
        </Grid>
      ) : (
        <Grid container justify="center">
          <Grid container item xs={12} spacing={3} alignItems="flex-end">
            {menus.map((item) => {
              return (
                <>
                  <Grid item xs={6} sm={3}>
                    <MenuCard
                      menuItem={item}
                      subMenuItem={item.sub_menus}
                      menuSubKindItem={item.menu_sub_kind}
                      handleOpen={handleClickOpen}
                    />
                  </Grid>
                </>
              );
            })}
          </Grid>
        </Grid>
      )}
      {/*)}*/}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export default function Order(props) {
  const classes = useStyles();
  const theme = useTheme();

  const {slipNo} = useParams();
  const [slip, setSlip] = React.useState({});
  const [menus, setMenus] = React.useState([]);
  const [subMenus, setSubMenus] = React.useState([]);
  const [menuKinds, setMenuKinds] = React.useState([]);
  // const [menuSubKinds, setMenuSubKinds] = React.useState([]);
  const [menu, setMenu] = React.useState(MenuJson);
  const [openOrderDialog, setOpenOrderDialog] = React.useState(false);
  const [selectedMenu, setSelectedMenu] = React.useState({
    menu: {
      menu_name: '',
    }
  });
  const [value, setValue] = React.useState(0);
  const [cart, setCart] = React.useState([]);
  const [cartOpen, setCartOpen] = React.useState(false);
  const [history, setHistory] = React.useState();
  const [historyOpen, setHistoryOpen] = React.useState(false);
  const [loadingHistory, setLoadingHistory] = React.useState(false);
  const [loadingMenu, setLoadingMenu] = React.useState(false);
  const [openProgress, setOpenProgress] = React.useState(false);

  const filterMenus = value => _.filter(menus, {menu_sub_kind_id: value});

  const menuSubKinds = value => value.menu_sub_kinds;
  const listMenuSubKinds = items => {
    return window._.flatMap(items, menuSubKinds);
  }

  const totalCart = items => {
    return window._.sumBy(items, 'quantity');
  }
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const handleClickOpen = (menu, subMenu, menuSubKind) => {
    setSelectedMenu({menu, subMenu, menuSubKind});
    setOpenOrderDialog(true);
  };

  const handleClose = () => {
    setOpenOrderDialog(false);
  };

  const updateMenuDiff = async () => {
    const response = await fetchMenuDiff();
    const menuDiff = response.data;

    if (menuDiff.length > 0) {
      const newMenu = [...menus];
      menuDiff.forEach(menuDiff => {
        const index = newMenu.findIndex(val => val.id === menuDiff.id);
        newMenu.splice(index, 1, menuDiff);
      })
      setMenus(newMenu);
    }

    return response;
  };

  const updateMenu = async (ids) => {
    const menuByIds = await fetchMenuByIds(ids);
    let menuByIdsData = [...menuByIds.data];
    const updated = _.some(menuByIdsData, 'status');

    let menuDiff = [];
    if (updated) {
      const response = await updateMenuDiff();
      menuDiff = response.data;
    }

    return menuDiff;
  };

  const handleCart = async (data) => {
    // 商品が欠品していないかチェック&メニューアップデート
    const ids = [data.menuId];
    const updated = await updateMenu(ids);
    if (updated.length) {
      alert('申し訳ありません、この商品は売り切れました。ご注文することができません。');
      setOpenOrderDialog(false);
      return;
    }

    const newCarts = [...cart];
    newCarts.push(data);
    setCart(newCarts);
    localStorage.setItem('cart', JSON.stringify(newCarts));
    setOpenOrderDialog(false);
  };

  const fetchMenuByIds = (ids) => {
    const result = axios.get(
      process.env.REACT_APP_API_ENDPOINT + '/api/menu/getByIds', {
        params: {
          ids,
        },
      },
    );
    return result;
  };

  const fetchMenuDiff = () => {
    const result = axios.get(
      process.env.REACT_APP_API_ENDPOINT + '/api/menu/getDiff', {
        params: {
          slipNo,
        },
      },
    );
    return result;
  };

  const handleCartOpen = () => {
    if (cart.length <= 0) {
      window.alert("商品をカートに入れてください。");
      return;
    }

    setCartOpen(true);
  }
  const onCloseCartDialog = (data) => {
    const newCarts = [...data];
    setCart(newCarts);
    localStorage.setItem('cart', JSON.stringify(newCarts));
    setCartOpen(false);
  }

  const fetchHistoryData = async () => {
    const result = await axios(
      process.env.REACT_APP_API_ENDPOINT + '/api/order/history/' + slipNo,
    );
    setHistory(result.data);
    setLoadingHistory(false);
  };

  const handleHistoryOpen = () => {
    setLoadingHistory(true);
    fetchHistoryData();
    setHistoryOpen(true);
  }
  const onCloseHistoryDialog = () => {
    setHistoryOpen(false);
  }

  const handleOrder = async (data) => {
    if (data.length <= 0) {
      window.alert("商品をカートに入れてください。");
      return;
    }

    let newCarts = [...data];
    setCart(newCarts);
    localStorage.setItem('cart', JSON.stringify(newCarts));
    // if (!window.confirm("注文を送信します。よろしいですか？")) {
    //   return;
    // }

    setOpenProgress(true);

    const ids = newCarts.map(item => item.menuId)
    const updated = await updateMenu(ids);
    const soldOuts = updated.filter(item => item.status === 1);
    let soldOutMenus = newCarts.filter(item =>
      soldOuts.findIndex(({id}) => id === item.menuId) !== -1
    );
    // 重複するメニューを削除
    soldOutMenus = _.uniqBy(soldOutMenus, 'menuId');
    if (soldOutMenus.length) {
      let itemNames = '';
      soldOutMenus.forEach(function (soldOut) {
        itemNames += `「${soldOut.menuName}」`;
      })

      // 売り切れした商品を削除
      newCarts = newCarts.filter(item =>
        soldOutMenus.findIndex(({menuId}) => menuId === item.menuId) === -1
      );
      setCart(newCarts);
      localStorage.setItem('cart', JSON.stringify(newCarts));

      if (newCarts.length > 0) {
        alert(`申し訳ありません、${itemNames}は売り切れました。他のご注文を送信します。`);
      } else {
        alert(`申し訳ありません、${itemNames}は売り切れました。ご注文はキャンセルされました。`);
        setOpenProgress(false);
        setCartOpen(false);
        return;
      }
    }
    axios.post(process.env.REACT_APP_API_ENDPOINT + '/api/order', {order: newCarts})
      .then((results) => {
          // 以下のGoogle API のレスポンスの例を元に欲しいデータを取得
          setCart([]);
          localStorage.removeItem('cart');
          setOpenProgress(false);
          setCartOpen(false);
          // すでにお会計済みの場合
          if (results.data.status === 1) {
            alert('すでにお会計を済みとなっていますのでご注文はできません。');
            window.location.href = process.env.REACT_APP_BASE_URL + '/thankyou';
          } else if (results.data.status === 2) {
            alert('お会計中となっていますのでご注文はできません。');
            window.location.href = process.env.REACT_APP_BASE_URL + '/check';
          }
        },
      );
  };

  React.useEffect(() => {
    let timer = setInterval(() => {
      const response = updateMenuDiff();
    }, 60000 * 2);
    return () => {
      clearInterval(timer);
    };
  }, [menus]);

  React.useEffect(() => {
    const fetchMenuData = async () => {
      setLoadingMenu(true);
      const result = await axios(
        process.env.REACT_APP_API_ENDPOINT + '/api/menu/' + slipNo,
      );
      props.setSlip(result.data.slip);
      setSlip(result.data.slip);
      setMenus(result.data.menus);
      setMenuKinds(result.data.menuKinds);
      console.log('set menus response to data! ');
      setLoadingMenu(false);
      if (result.data.slip.status === 2) {
        window.location.href = process.env.REACT_APP_BASE_URL + '/check';
      } else if (result.data.slip.status === 1) {
        window.location.href = process.env.REACT_APP_BASE_URL + '/thankyou';
      }
    };

    fetchMenuData();

    // cart情報がローカルストレージにあったら設定
    const lsCart = localStorage.getItem('cart');
    if (lsCart) {
      setCart(JSON.parse(lsCart));
    }

  }, []);

  let menuTabIndex = 0;

  return (
    <div>
      <Typography
        variant="h6"
        align="center"
      >
        {loadingMenu ? (
          <Skeleton
            animation="wave"
            style={{margin: 6}}
          />
        ) : (
          slip.table_no ? (
            `${slip.table_no}番テーブル / ${slip.people}名様`
          ) : (
            <Skeleton
              animation="wave"
              style={{margin: 6}}
            />
          )
        )}
      </Typography>
      <React.Fragment>
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            // variant="fullWidth"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="full width tabs example"
          >
            {loadingMenu ? (
              Array.from(new Array(5)).map((row, index) => {
                return (
                  <Skeleton
                    width={60}
                    animation="wave"
                    style={{margin: 6}}
                  />
                );
              })
            ) : (
              listMenuSubKinds(menuKinds).map((menuSubKind, index) => {
                return (
                  <Tab
                    label={menuSubKind.menu_sub_kind_name}
                    {...a11yProps(index)}
                  />
                );
              })
            )}
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={value}
          onChangeIndex={handleChangeIndex}
          style={{
            paddingBottom: 56,
          }}
        >
          {loadingMenu ? (
            <TabPanel
              loading={true}
              // value={value}
              // index={index}
              dir={theme.direction}
              // content={menuSubKind.menu_sub_kind_name}
              // menus={filterMenus(menuSubKind.menu_sub_kind_id)}
              // handleClickOpen={handleClickOpen}
            >
              {/*{menuSubKinds.menu_sub_kind_name}*/}
            </TabPanel>
          ) : (
            listMenuSubKinds(menuKinds).map((menuSubKind, index) => {
              return (
                <TabPanel
                  loading={false}
                  value={value}
                  index={index}
                  dir={theme.direction}
                  content={menuSubKind.menu_sub_kind_name}
                  menus={filterMenus(menuSubKind.menu_sub_kind_id)}
                  handleClickOpen={handleClickOpen}
                  style={{
                    overflow: 'hidden',
                  }}
                >
                  {menuSubKinds.menu_sub_kind_name}
                </TabPanel>
              );
            })
          )}
        </SwipeableViews>
        <OrderDialog
          open={openOrderDialog}
          slip={slip}
          selectedMenu={selectedMenu}
          handleClose={handleClose}
          handleCart={handleCart}
        />
        <ScrollToTop {...props} />
        <BottomMenu
          handleCartOpen={handleCartOpen}
          handleHistoryOpen={handleHistoryOpen}
          handleOrder={() => handleOrder(cart)}
          cartCount={totalCart(cart)}
        />
        <CartDialog
          root={props.root}
          handleOrder={handleOrder}
          onCloseDialog={onCloseCartDialog}
          open={cartOpen}
          cart={cart}
          openProgress={openProgress}
        />
        <HistoryDialog
          root={props.root}
          onCloseDialog={onCloseHistoryDialog}
          open={historyOpen}
          history={history}
          loading={loadingHistory}
        />
      </React.Fragment>
    </div>
  );
}
