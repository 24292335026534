import {makeStyles} from "@material-ui/core/styles";
import {blue, green, red} from "@material-ui/core/colors";
import {Chip} from "@material-ui/core";
import React from "react";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  statusServed: {
    backgroundColor: blue[500],
    color: '#fff',
  },
  statusNotServed: {
    backgroundColor: red[500],
    color: '#fff',
  },
  statusCanceled: {
    backgroundColor: green[500],
    color: '#fff',
  },
}));

export default function Status(props) {
  const styles = useStyles();
  let name = '';
  let color = styles.statusCanceled;
  switch (props.status) {
    case 'C':
      name = '取　消'
      // name = '消'
      color = styles.statusCanceled;
      break;
    case 'T':
      name = '提供済'
      // name = '済'
      color = styles.statusServed;
      break;
    case 'M':
      name = '未提供'
      // name = '未'
      color = styles.statusNotServed;
      break;
  }
  return (
    <strong>
      <Chip
        className={color}
        size="small"
        color={color}
        label={name}
      />
    </strong>
  );
}
