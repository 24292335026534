import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import Spacer from "./Spacer";
import Progress from "./Progress";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "unset",
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  statLabel: {
    fontSize: 12,
    color: theme.palette.grey[500],
    fontWeight: 500,
    fontFamily:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    margin: 0,
  },
  statValue: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 4,
    letterSpacing: '1px',
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


export default function CartDialog(props) {
  const classes = useStyles();
  const [cart, setCart] = React.useState([]);

  const handleDelete = (index) => {
    if (!window.confirm("注文を削除します。よろしいですか？")) {
      return;
    }
    const newCart = [...cart];
    newCart.splice(index, 1);
    setCart(newCart);
  };
  const handleQuantityPlus = (index) => {
    const quantity = cart[index].quantity;
    const newCart = [...cart];
    newCart[index].quantity = quantity + 1;
    setCart(newCart);
  };
  const handleQuantityMinus = (index) => {
    const quantity = cart[index].quantity;
    if (quantity > 1) {
      const newCart = [...cart];
      newCart[index].quantity = quantity - 1;
      setCart(newCart);
    }
  };
  const handleClose = () => {
    props.onCloseDialog(cart);
  };
  const handleOrder = () => {
    props.handleOrder(cart);
  };

  React.useEffect(() => {
    setCart(props.cart);
  }, [props.cart]);

  return (
    <div>
      <Dialog fullScreen open={props.open} onClose={handleClose}
              TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit"
                        onClick={handleClose} aria-label="close">
              <CloseIcon/>
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              カート
            </Typography>
            <Button autoFocus color="inherit" onClick={handleOrder}>
              注文を送信する
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent
          className={classes.root}
          // dividers
        >
          <List>
            {cart.map((val, index) => (
              <>
                <ListItem key={index}>
                  <Grid
                    container
                    direction="column"
                    // justify="center"
                    // alignItems="center"
                    // spacing={0}
                  >
                    <Grid
                      item
                      // xs={4}
                    >
                      <Typography
                        variant="subtitle1"
                        // align="center"
                      >
                        {val.menuName}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      // xs={4}
                    >
                      <Typography
                        variant="caption"
                        // align="center"
                      >
                        {val.subMenuName}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      // container
                      direction="row"
                      // justify="flex-end"
                      alignItems="center"
                      // spacing={3}
                    >
                      <Grid
                        // item
                        container
                        // direction="row"
                        // justify="flex-end"
                        alignItems="center"
                        // spacing={3}
                      >
                        <Grid
                          item
                          // justify="flex-end"
                          // alignItems="center"
                        >
                          <Typography
                            variant="subtitle1"
                            align="center"
                          >
                            {`¥${val.price + val.subMenuPrice}`}
                          </Typography>
                        </Grid>
                        <Spacer/>
                        <Grid
                          item
                          justify="flex-end"
                          // xs={4}
                        >
                          <IconButton
                            color="secondary"
                            onClick={() => handleQuantityMinus(index)}
                          >
                            <Icon
                              style={{fontSize: 20}}>remove_circle</Icon>
                          </IconButton>
                        </Grid>
                        <Grid
                          item
                          // xs={4}
                        >
                          <Typography
                            variant="subtitle1"
                            align="center"
                          >
                            {val.quantity}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          // xs={4}
                        >
                          <IconButton
                            color="primary"
                            onClick={() => handleQuantityPlus(index)}
                          >
                            <Icon style={{fontSize: 20}}>add_circle</Icon>
                          </IconButton>
                        </Grid>
                        <Grid
                          item
                          // xs={4}
                        >
                          <IconButton
                            color="primary"
                            onClick={() => handleDelete(index)}
                          >
                            <Icon style={{fontSize: 20}}>clear</Icon>
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </ListItem>
                <Divider component="li"/>
              </>
            ))}
          </List>
        </DialogContent>
      </Dialog>
      <Progress
        open={props.openProgress}
        message="注文を送信しています..."
      />
    </div>
  );
}

CartDialog.defaultProps = {
  openProgress: false,
  cart: [
    // {
    //   id: null,
    //   name: '',
    //   employee_id: '',
    //   branch_id: ''
    // }
  ]
};

CartDialog.propTypes = {
  openProgress: PropTypes.bool.isRequired,
  cart: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      employee_id: PropTypes.string,
      branch_id: PropTypes.string,
    })
  ).isRequired,
}
