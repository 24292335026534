// react component
import React from 'react';
import cx from 'clsx';

// material-ui component
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import TextInfoContent from '@mui-treasury/components/content/textInfo';

// material-ui extend component
import {useFourThreeCardMediaStyles} from '@mui-treasury/styles/cardMedia/fourThree';
import {useN04TextInfoContentStyles} from '@mui-treasury/styles/textInfoContent/n04';
import {useOverShadowStyles} from '@mui-treasury/styles/shadow/over';

// original component
import OrderDialog from './OrderDialog';
import {
  CardHeader,
  Divider,
  Box,
  Typography,
} from "@material-ui/core";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";

// const useStyles = makeStyles(() => ({
//   root: {
//     maxWidth: 343,
//     margin: 'auto',
//     borderRadius: 12,
//     padding: 12,
//   },
//   media: {
//     borderRadius: 6,
//   },
// }));
const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 12,
    // minWidth: 256,
    textAlign: 'center',
  },
  disabled: {
    pointerEvents: 'none',
    backgroundColor: 'LightGray',
    opacity: 0.7,
  },
  header: {
    textAlign: 'center',
    spacing: 10,
  },
  list: {
    padding: '20px',
  },
  button: {
    margin: theme.spacing(1),
  },
  action: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  image: {
    width: '-webkit-fill-available',
    height: 100,
  },
}));

// export default function MenuCard(props) {
export const MenuCard = React.memo(function MenuCard(props) {
  const classes = useStyles();
  const mediaStyles = useFourThreeCardMediaStyles();
  const textCardContentStyles = useN04TextInfoContentStyles();
  const shadowStyles = useOverShadowStyles({inactive: true});

  const {menuItem, subMenuItem, menuSubKindItem, handleOpen} = props;
  const {user_id, branch_id, category_name, menu_name, price, status, menu_image, campaign_menu_message} = props.menuItem;

  const handleClickOpen = () => {
    handleOpen(menuItem, subMenuItem, menuSubKindItem);
  };

  const imageUrl = process.env.REACT_APP_API_ENDPOINT;

  const menuName = (name, status, campaignMenuMessage) => {
    let menuName = name;

    if (campaignMenuMessage !== null) {
      menuName = `${campaignMenuMessage.prefix}${menuName}${campaignMenuMessage.suffix}`;
    }

    if (status === 0) {
      return menuName;
    } else {
      return `[売切]${menuName}`;
    }
  }
  const defaultMenuImage = (image) => image !== '' ? `${imageUrl}/resources/images/menu/${user_id}/${branch_id}/${image}` : `${imageUrl}/resources/images/no-image.png`;

  return (
    <>
      <Card
        className={`${cx(classes.root)} ${status === 0 ? '' : classes.disabled}`}
        onClick={handleClickOpen}
      >
        {menu_image ? (
          <img
            className={classes.image}
            // sizes="(max-width: 960px) 100vw, 960px"
            // srcSet={menu_image}
            src={defaultMenuImage(menu_image)}
            alt=""/>
          ) : (
            <>
            </>
        )}
        <CardHeader
          title={menuName(menu_name, status, campaign_menu_message)}
          className={classes.header}
          titleTypographyProps={{
            variant: "fontSize",
          }}
        />
        <Divider variant="middle"/>
        <CardContent>
          <Typography component="div">
            <Box fontSize={16} m={1}>
              {`${price}円`}
            </Box>
          </Typography>
        </CardContent>
      </Card>
    </>
  );
});
export default MenuCard;
